import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins/validations'
import { ValidationObserver, ValidationProvider} from 'vee-validate';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@babel/polyfill'
import '@/assets/style.css'
import axios from "axios";
import router from './router'
import VCurrencyField from 'v-currency-field'
import DatetimePicker from 'vuetify-datetime-picker'
import VueLazyload from 'vue-lazyload'
import i18n from './i18n'
import { VueSpinners } from '@saeris/vue-spinners'
import EncryptValue from '@/plugins/encrypt'
import getCountryInitial from '@/plugins/getCountryInitial'
import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon);

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueLazyload)
Vue.use(VueSpinners)

Vue.use(DatetimePicker);

axios.interceptors.request.use(
  function(config) {
        config.withCredentials = true;
        config.headers['Content-Type'] = "application/json";
        config.headers['Accept'] = "application/json";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

Vue.prototype.$fatalError = true;

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})



Vue.prototype.$encryptValue = EncryptValue
Vue.prototype.$getCountryInitial = getCountryInitial

Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})


new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
