<template>
    <v-container fluid>
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>

        <v-row justify="center" v-if="!loading">
            <v-col cols="12" md="8">
                <v-container fluid class="mt-12 py-12">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-img src="@/assets/fundo_conduril.png" max-width="35%"/>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-select @change="languageChoosed" v-model="$i18n.locale" :items="languages" item-text="language" :label="$t('globals.choose_language')">
                                <template v-slot:selection="{  }">
                                    <flag class="mr-4" :iso="$i18n.locale == 'en' ? 'gb': $i18n.locale" /> {{getExtension()}}
                                </template>
                                <template v-slot:item="{ item }">
                                    <flag class="mr-4" :iso="item.language == 'en' ? 'gb' : item.language" />{{item.extension}}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card class="elevation-8">
                    <v-card-title class="primary white--text text-h2" v-html="fields.i18n[$i18n.locale].title">
                        
                    </v-card-title>
                    <v-card-text class="mt-4" v-html="fields.i18n[$i18n.locale].description">
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="primary" @click="$router.push($i18n.locale + '/complaint')">{{$t('globals.create_complaint')}}</v-btn>
                        <v-btn outlined @click="checkComplaint = true">{{$t('globals.check_complaint')}}</v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

        <v-dialog
            v-model="checkComplaint"
            width="600"
            persistent
            >

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{$t('home.insert_pwd')}}
                </v-card-title>

                <v-card-text class="mt-4">
                    {{$t('home.given')}}
                </v-card-text>
                <v-card-text>
                    <v-text-field
                        v-model="password"
                        prepend-inner-icon="mdi-account-details"
                        label="Password *"
                        clearable
                    ></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-btn
                    color="error"
                    @click="checkComplaint = false;"
                >
                    {{$t('globals.close')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="checkComplaintFunc"
                >
                    {{$t('globals.check')}}
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
            
    </v-container>
</template>
<script>
import Complaints from '@/api/Complaints.js'
import Languages from '@/api/Languages.js'

export default{
    name: "Homepage",
    data(){
        return{
            loading: true,
            fileds:{},
            languages: [],
            checkComplaint: false,
            password: null
        }
    },
    mounted(){
        this.loading = true;
        this.fillBaseData()
    },
    methods:{
        getExtension(){
            let returner = 'Português';
            this.languages.forEach(element => {
                
                if(element.language == this.$i18n.locale)
                    returner = element.extension
            });

            return returner
        },
        checkComplaintFunc(){
            if(this.password == null)
                return

            this.checkComplaint = false; 
            this.$router.push(this.$i18n.locale + '/complaint/' + this.password)
        },
        languageChoosed(l){
            this.$i18n.locale = l
        },
        async fillBaseData(){
            await Complaints.getIntroduction().then(({data}) => {
                this.fields = data
            });
            
            await Languages.list().then(({data}) => {
                this.languages = data
            });

            this.loading = false
        }
    }
}
</script>