<template>
    <v-container fluid>    
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-container fluid class="mt-12 py-12">
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-img src="@/assets/fundo_conduril.png" max-width="35%"/>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select @change="languageChoosed" v-model="$i18n.locale" :items="languages" item-text="language" :label="$t('globals.choose_language')">
                                <template v-slot:selection="{  }">
                                    <flag class="mr-4" :iso="$i18n.locale == 'en' ? 'gb': $i18n.locale" /> {{getExtension()}}
                                </template>
                                <template v-slot:item="{ item }">
                                    <flag class="mr-4" :iso="item.language == 'en' ? 'gb' : item.language" />{{item.extension}}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card class="elevation-8 ma-0 pa-0">
                    <v-card-text class="ma-0 pa-0">
                      <complaint method="create"></complaint>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
  </template>
  <script>
import Complaint from '@/components/complaints/Complaint'
import Languages from '@/api/Languages.js'
  
export default {
  components: {
      Complaint
  },
  data(){
    return{
      loading: true,
      languages: []
    }
  },
  mounted(){
    this.fillBaseData()
  },
  methods:{
    getExtension(){
        let returner = 'Português';
        this.languages.forEach(element => {
            
            if(element.language == this.$i18n.locale)
                returner = element.extension
        });

        return returner
    },
    languageChoosed(l){
        this.$i18n.locale = l
    },
    async fillBaseData(){
      await Languages.list().then(({data}) => {
          this.languages = data
      });
      this.loading = false
    }
  },
};
</script>
  