<template>
        <v-app>
          <v-container fluid class="ma-0 pa-0">
            <v-app-bar class="primary" dense>
              <v-avatar
                size="40px"              
              >
                <img
                  alt="Avatar"
                  src="@/assets/icon_dream.png"
                >
              </v-avatar> 
            </v-app-bar>
          </v-container>
          <v-main>
            <v-container fluid class="ma-0 pa-0">              
              <router-view></router-view>
            </v-container>
          </v-main>

          <v-footer 
            fluid
            dark
            class="primary"
            fill-height>
            <span class="text-body-1  " style="margin: auto; display: block;">
              {{$t('footer.developed_by') }} {{geyDateYear()}}
            </span>
          </v-footer>
            
        </v-app>
        
</template>

<script>
  export default {
    components:{
    },
    data(){
      return{
      }
    },
    mounted(){
      
    },
    methods:{
      geyDateYear(){
        let d = new Date()
        return d.getFullYear()
      }
    },
    computed:{
    }
  }
</script>